<script>
import axios from 'axios'
import { BeaconapiUrl } from '../constants/config'
import { mapMutations } from 'vuex'
import { instance } from '../../src/request/app-axios.js'

export default {
  data () {
    return {
      employees: [],
      employeesByRole: [],
      employee: {},
      roles: [],
      permissions: [],
      employeeRole: {},
      employeePermissions: [],

      statusCode: null
    }
  },
  methods: {
    ...mapMutations([
      'setDangerMessage',
      'setAlertMessage',
      'setInfoMessage',
      'appendInfoMessage',
      'appendDangerMessage'
    ]),
    async getUsersAvailableToAddToHorizon () {
      return await axios
        .get(`${BeaconapiUrl}/api/authorization/users-to-add`)
        .then(result => {
          this.employees = result.data
          return result.data
        })
        .catch(error => {
          this.setDangerMessage(error)
        })
    },
    async getAllActiveEmployees () {
      await axios
        .get(`${BeaconapiUrl}/api/authorization/employee/all`)
        .then(result => {
          this.employees = result.data
        })
        .catch(error => {
          this.setDangerMessage(error)
        })
    },
    async reactivateEmployeeInHorizon (employee) {
      employee.LastModifiedByID = localStorage.getItem('employeeID')
      await axios
        .put(`${BeaconapiUrl}/api/authorization/employee`, employee)
        .then(result => {
          this.statusCode = result.status
          this.setInfoMessage(`${result.data}`)
        })
        .catch(error => {
          this.setDangerMessage(error)
        })
    },
    async addEmployeeToHorizon (employee) {
      await instance
        .post(`${BeaconapiUrl}/api/authorization/employee`, employee)
        .then(result => {
          this.statusCode = result.status
          this.setInfoMessage(`${result.data}`)
        })
        .catch(error => {
          this.setDangerMessage(error)
        })
    },
    async deactivateEmployee (employee) {
      employee.lastModifiedByID = localStorage.getItem('employeeID')
      await axios
        .put(`${BeaconapiUrl}/api/authorization/employee/deactivate`, employee)
        .then(result => {
          this.statusCode = result.status
          this.setInfoMessage(result.data)
        })
        .catch(error => {
          this.setDangerMessage(error)
        })
    },
    async getAllPermissions () {
      return await axios
        .get(`${BeaconapiUrl}/api/authorization/permission`)
        .then(result => {
          this.permissions = result.data
        })
        .catch(error => {
          this.setDangerMessage(error)
        })
    },

    async getAllRoles () {
      return await axios
        .get(`${BeaconapiUrl}/api/authorization/role`)
        .then(result => {
          this.roles = result.data
        })
        .catch(error => {
          this.setDangerMessage(error)
        })
    },
    async getEmployeeRole (type, id) {
      return await axios
        .get(`${BeaconapiUrl}/api/authorization/employee-role/?${type}=${id}`)
        .then(result => {
          this.employeeRole = result.data
        })
        .catch(error => {
          this.setDangerMessage(error)
        })
    },
    async getEmployeePermissionByID (employeeID) {
      return await axios
        .get(
          `${BeaconapiUrl}/api/authorization/employee-permission/${employeeID}`
        )
        .then(result => {
          this.employeePermissions = result.data
        })
        .catch(error => {
          this.setDangerMessage(error)
        })
    },
    async saveEmployeeRole (employeeRole) {
      await instance
        .post(`${BeaconapiUrl}/api/authorization/employee-role`, employeeRole)
        .then(result => {
          this.statusCode = result.status
          result.data.forEach(addedRole => {
            this.appendInfoMessage(addedRole)
          })
        })
        .catch(error => {
          this.setDangerMessage(error)
        })
    },
    async deleteEmployeeRole (employeeRole) {
      await axios
        .delete(`${BeaconapiUrl}/api/authorization/employee-role`, {
          data: employeeRole
        })
        .then(result => {
          this.statusCode = result.status
          this.appendInfoMessage(result.data)
        })
        .catch(error => {
          this.setDangerMessage(error)
        })
    },
    async saveEmployeePermission (employeePermission) {
      await instance
        .post(
          `${BeaconapiUrl}/api/authorization/employee-permission`,
          employeePermission
        )
        .then(result => {
          this.statusCode = result.status
          result.data.forEach(addedPermission => {
            this.appendInfoMessage(addedPermission)
          })
        })
        .catch(error => {
          this.setDangerMessage(error)
        })
    },
    async deleteEmployeePermission (employeePermission) {
      await axios
        .delete(`${BeaconapiUrl}/api/authorization/employee-permission`, {
          data: employeePermission
        })
        .then(result => {
          this.statusCode = result.status
          this.appendInfoMessage(result.data)
        })
        .catch(error => {
          this.setDangerMessage(error)
        })
    },
    async getEmployeesByRole (roleID, roleName) {
      await axios
        .get(
          `${BeaconapiUrl}/api/authorization/role/employees`, { params: { roleID: roleID } }
        )
        .then(result => {
          this.statusCode = result.status
          var employeeList = result.data.map(e => {
            return {
              name: `${e.displayName}`,
              employeeID: e.employeeID,
              roleID: roleID
            }
          })
          this.employeesByRole.push({
            roleName: roleName,
            roleID: roleID,
            selectedEmployee: undefined,
            employees: employeeList
          })
        })
        .catch(error => {
          this.setDangerMessage(error)
        })
    },
    async getEmployee (employeeID) {
      return await axios
        .get(`${BeaconapiUrl}/api/authorization/employee/${employeeID}`)
        .then(result => {
          this.employee = result.data
        })
        .catch(error => {
          this.setDangerMessage(error)
        })
    }
  },
  computed: {}
}
</script>
