<template>
  <b-modal
    id="modalbasic"
    v-b-modal.modal-center
    ref="modalbasic"
    @hide="close()"
    v-model="isVisible"
    @cancel="close()"
    @close="close()"
    :no-close-on-backdrop="true"
    size="xl"
  >
  <template #modal-title>
    <br><h1>{{ $t('menu.add-horizon-users') }}</h1>
  </template>

  <b-row>
    <b-colxx xxs="12">
      <confirmation ref="Confirmation"></confirmation>
      <datatable-heading
        :searchChange="_.debounce(searchChange, 500)"
        :from="from"
        :to="to"
        :total="dataCount"
        :perPage="perPage"
        :hasPageSizes="false"
        ref="tableHeader"
      >
      </datatable-heading>

      <b-card class="mb-4" :title="title" style="max-height: 25rem; overflow: scroll;">
        <b-overlay :show="gridLoading" variant="transparent" blur="5px" opacity="1">
            <vuetable ref="vuetable"
              :api-mode="false"
              :fields="fields"
              :data-total="dataCount"
              :data-manager="dataManager"
              :per-page="perPage"
              :css="css.table"
              :row-class="onRowClass"
              @vuetable:row-clicked="rowClicked"
              @vuetable:cell-rightclicked="rightClicked"
              @vuetable:load-error="handleLoadError"
              @vuetable:cell-clicked="cellclicked">
            </vuetable>
        </b-overlay>
        <v-contextmenu ref="contextmenu">
          <v-contextmenu-item @click="onContextMenuAction('add')"
            :disabled="((currentSelectedItem && currentSelectedItem.existsInHorizon) || isProcessing)">
            <i class="simple-icon-docs" />
            <span>Add</span>
          </v-contextmenu-item>
        </v-contextmenu>
      </b-card>
    </b-colxx>
  </b-row>
  </b-modal>
</template>
<script>
import confirmation from '../Common/Confirmation.vue'
import CssTableConfig from '../../constants/VuetableBootstrapconfig'
import DatatableHeading from '../../containers/datatable/DatatableHeading'
import EmployeeMixin from '../../mixins/EmployeeMixin.vue'
import { mapMutations } from 'vuex'
import Vuetable from 'vuetable-2/src/components/Vuetable'

export default {
  props: ['title'],
  components: {
    vuetable: Vuetable,
    'datatable-heading': DatatableHeading,
    Confirmation: confirmation
  },
  mixins: [EmployeeMixin],
  data () {
    var _idfield = 'employeeID'
    return {
      usersAvailableToAdd: {},
      perPage: 100,
      searchFor: '',
      from: 0,
      to: 0,
      dataCount: 0,
      isVisible: false,
      isShow: false,
      isProcessing: false,
      selectedRow: null,
      selectedItems: [],
      idfield: _idfield,
      css: CssTableConfig,
      gridLoading: false,
      fields: [
        {
          name: 'displayName',
          sortField: 'displayName',
          title: 'Name',
          titleClass: '',
          dataClass: 'text-muted',
          width: '33%'
        },
        {
          name: 'emailAddress',
          sortField: 'emailAddress',
          title: 'Email',
          titleClass: '',
          dataClass: 'text-muted',
          width: '33%'
        },
        {
          name: 'azureGuid',
          sortField: 'azureGuid',
          title: 'Azure Guid',
          titleClass: '',
          dataClass: 'text-muted',
          width: '33%'
        }
      ],
      currentSelectedItem: null
    }
  },
  watch: {
    usersAvailableToAdd (newVal, oldVal) {
      this.$refs.vuetable.refresh()
    }
  },
  async created () {
    await this.refreshTableData(false)
  },
  mounted () {
    this.$refs.vuetable.setData(this.usersAvailableToAdd)
  },
  methods: {
    ...mapMutations(['setDangerMessage', 'setAlertMessage', 'setInfoMessage', 'appendInfoMessage']),

    // Vue Table plumbing methods (standardized)
    onPaginationData (paginationData) {
      this.from = paginationData.from
      this.to = paginationData.to
      this.total = paginationData.total
      this.lastPage = paginationData.last_page
      this.items = paginationData.data
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    changePageSize (perPage) {
      this.perPage = perPage
      this.refreshVueTable()
    },
    searchChange (val) {
      this.searchFor = val
      this.refreshVueTable()
    },
    handleLoadError (response) {
      this.setDangerMessage(response)
    },
    onRowClass (dataItem, index) {
      var outputclass = ''
      if (this.selectedItems.includes(dataItem[this.$data.idfield])) {
        outputclass = 'selected'
      }
      return outputclass
    },
    cellclicked (dataitem, field, event) {
    },
    rightClicked (dataItem, field, event) {
      event.preventDefault()
      this.rightClickedItem = dataItem
      this.currentSelectedItem = dataItem
      const itemId = dataItem[this.$data.idfield]

      if (!this.$refs.vuetable.selectedTo.includes(itemId)) {
        this.$refs.vuetable.selectedTo = [itemId]
      }
      this.$refs.contextmenu.show({ top: event.pageY, left: event.pageX })
      this.selectedItems = this.$refs.vuetable.selectedTo
    },
    getIndex (value, arr, prop) {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i][prop] === value) {
          return i
        }
      }
      return -1
    },
    refreshVueTable () {
      this.$refs.vuetable.refresh()
    },
    rowClicked (dataItem, event) {
      const itemId = dataItem[this.$data.idfield]

      if (this.$refs.vuetable.selectedTo.includes(itemId)) {
        this.$refs.vuetable.selectedTo = this.$refs.vuetable.selectedTo.filter(x => x !== itemId)
      } else {
        this.$refs.vuetable.selectedTo.push(itemId)
      }
      this.selectedItems = this.$refs.vuetable.selectedTo
    },

    // End of Standard Vue Table methods

    // Component-specific Vue Table methods

    onContextMenuAction (action) {
      switch (action) {
        case 'add':
        {
          this.addUserToHorizon(this.currentSelectedItem)
          break
        }
        default:
          break
      }
    },

    // Any interaction with the vuetable will fire the dataManager.
    // Interactions include: sorting, searching, changing pages, etc.
    // Since there is no binding directly from the vuetable to the list data
    // you will need to use this.$refs.vuetable.setData(this.listData) to
    // force the dataManager to fire and update the vuetable list data
    dataManager (sortOrder, pagination) {
      let data = this.usersAvailableToAdd

      // Account for search filter
      if (this.searchFor) {
        // The text should be case insensitive
        const txt = new RegExp(this.searchFor, 'i')

        // Search on name, email, and nickname
        data = _.filter(data, function (item) {
          return (
            item.azureGuid.search(txt) >= 0 ||
            item.displayName.search(txt) >= 0 ||
            item.emailAddress.search(txt) >= 0
          )
        })
      }

      // sortOrder can be empty, so we have to check for that as well
      if (sortOrder.length > 0) {
        data = _.orderBy(data, sortOrder[0].sortField, sortOrder[0].direction)
      }

      // Since the filter might affect the total number of records
      // we can ask vuetable to recalculate the pagination for us
      // by calling makePagination(). This will make VuetablePagination
      // work just like in API mode

      // pagination = this.$refs.vuetable.makePagination(data.length)

      // If you don't want to use pagination component, you can just
      // return the data array
      return {
        // pagination: pagination,
        // data: _.slice(data, pagination.from - 1, pagination.to)
        data: data
      }
    },
    async refreshTableData (loading) {
      // Have to set gridLoading in this call since data mode never fires loading or loaded events
      if (loading) {
        this.gridLoading = true
      }
      await this.getUsersAvailableToAddToHorizon()
        .then(response => {
          this.usersAvailableToAdd = response
          this.perPage = this.usersAvailableToAdd.length
          this.from = 1
          this.to = this.usersAvailableToAdd.length
          this.dataCount = this.usersAvailableToAdd.length
          this.isProcessing = false
          this.$refs.vuetable.setData(this.usersAvailableToAdd)
          var tableHeader = this.$refs.tableHeader
          window.scrollTo(0, tableHeader.offsetTop)
          this.gridLoading = false
        })
    },

    // End of Component-specific Vue Table methods

    // Component-specific Methods
    async show (opts = {}) {
      this.title = opts.title

      if (opts.exitFunction) {
        this.exitFunction = opts.exitFunction
      }

      this.isVisible = true
    },
    async addUserToHorizon (employee) {
      this.isProcessing = false

      await this.$refs.Confirmation.show({
        title: 'Add Employee to Horizon',
        message: `Are you sure you want to add ${employee.displayName} to Horizon?`,
        okButton: 'Yes'
      }).then(async (result) => {
        if (result) {
          this.selectedRow = employee.azureGuid
          this.isProcessing = true

          this.setInfoMessage('')
          await this.addEmployeeToHorizon(employee)
            .then(async _ => {
              await this.refreshTableData(true)
            })
            .catch(err => {
              this.isProcessing = false

              if (err.response) {
                // client received an error response (5xx, 4xx)
                this.setDangerMessage(err.message)
              } else if (err.request) {
                // client never received a response, or request never left
                this.setDangerMessage(err.message)
              } else {
                // anything else
                this.setDangerMessage(err.message)
              }
            })
        } else {
          this.$refs.vuetable.refresh()
        }
      })
    },
    close () {
      this.searchFor = ''
      if (this.exitFunction) {
        this.exitFunction()
      }

      this.setModalDangerMessage('')
      this.setModalInfoMessage('')
      this.isVisible = false
    }
  }

  // End of Component-specific methods
}
</script>
