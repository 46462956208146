<template>
<div>
  <confirmation ref="Confirmation"></confirmation>
  <addhorizonuser-modal ref="AddHorizonUserModal"></addhorizonuser-modal>
  <datatable-heading
    :title="$t('menu.horizon-users')"
    :changePageSize="changePageSize"
    :searchChange="_.debounce(searchChange, 500)"
    :from="from"
    :to="to"
    :total="total"
    :perPage="perPage"
    :tableFields="fields"
    @filterChange="filterChange"
  >
  <template v-slot:button>
      <div class="title-and-right-element">
        <b-button
          v-b-modal.modalright
          variant="primary"
          size="lg"
          class="top-right-button mr-2"
          @click="addUserToHorizon()"
        >Add</b-button>
      </div>
  </template>
  </datatable-heading>

  <b-overlay :show="gridLoading" variant="transparent" blur="5px" opacity="1">
    <b-row>
      <b-colxx xxs="12">
        <b-card>
          <vuetable
            ref="vuetable"
            :api-url="apiBase"
            :query-params="makeQueryParams"
            :per-page="perPage"
            :reactive-api-url="false"
            :fields="fields"
            :css="css.table"
            pagination-path
            :row-class="onRowClass"
            @vuetable:pagination-data="onPaginationData"
            @vuetable:row-clicked="rowClicked"
            @vuetable:cell-rightclicked="rightClicked"
            @vuetable:load-error="handleLoadError"
            @vuetable:cell-clicked="cellclicked"
            @vuetable:loading="onVuetableLoading"
            @vuetable:loaded="onVuetableLoaded"
          >
          <template slot="activeEmployee" slot-scope="props">
              <div style="display:flex;justify-content:center;align-items;center;">
                <ToggleSwitch
                  v-on:input.self="flipActive(props.rowData)"
                  v-model="props.rowData.active"
                  :emitOnMount="false"
                  theme="custom"
                  class="vue-switcher-small"
                  color="primary"
                  :id="'toggle-' + props.rowData.emailAddress"
                  v-b-tooltip.hover.left :title="`Activate/Deactivate ${props.rowData.displayName}`"
                />
              </div>
            </template>

            <template slot="roles" slot-scope="props">
                <ReadMoreList :linesToShow="1" separator="," :listText="props.rowData.roles"/>
            </template>

            <template slot="permissions" slot-scope="props">
              <ReadMoreList :linesToShow="1" separator="," :listText="props.rowData.permissions"/>
            </template>
          </vuetable>
        </b-card>
        <vuetable-pagination-bootstrap
          class="mt-4"
          ref="pagination"
          @vuetable-pagination:change-page="onChangePage"
        />
      </b-colxx>
    </b-row>
  </b-overlay>
  <v-contextmenu ref="contextmenu">
       <v-contextmenu-item @click="onContextMenuAction('roles_or_permissions')">
        <i class="simple-icon-docs" />
        <span>Roles/Permissions</span>
      </v-contextmenu-item>
    </v-contextmenu>
</div>
</template>
<script>
import manageCss from '../../../../assets/css/employees/manage-employees.css'
import confirmation from '../../../../components/Common/Confirmation.vue'
import Vuetable from 'vuetable-2/src/components/Vuetable'
// import Moment from 'moment/moment.js'
import VuetablePaginationBootstrap from '../../../../components/Common/VuetablePaginationBootstrap'
import Switches from 'vue-switches'
import { BeaconapiUrl } from '../../../../constants/config'

import moment from 'moment/moment.js'
import DatatableHeading from '../../../../containers/datatable/DatatableHeading'
import CssTableConfig from '../../../../constants/VuetableBootstrapconfig'
import EmployeeMixin from '../../../../mixins/EmployeeMixin.vue'
import { mapMutations, mapActions } from 'vuex'
import ReadMoreList from '../../../../components/Listing/ReadMoreList.vue'
import AddHorizonUserModal from '../../../../components/Modals/AddHorizonUserModal.vue'

export default {
  props: ['title'],
  components: {
    vuetable: Vuetable,
    'vuetable-pagination-bootstrap': VuetablePaginationBootstrap,
    'datatable-heading': DatatableHeading,
    Confirmation: confirmation,
    ToggleSwitch: Switches,
    ReadMoreList: ReadMoreList,
    'addhorizonuser-modal': AddHorizonUserModal
  },
  mixins: [EmployeeMixin],
  data () {
    var _idfield = 'employeeID'
    return {
      tooltipDisabled: false,
      isLoad: false,
      apiBase: BeaconapiUrl + '/api/authorization/manage-employees-grid',
      sort: '',
      page: 1,
      perPage: 50,
      search: '',
      from: 0,
      to: 0,
      total: 0,
      lastPage: 0,
      items: [],
      selectedItems: [],
      idfield: _idfield,
      css: CssTableConfig,
      gridLoading: false,
      fields: [
        {
          name: _idfield,
          sortField: _idfield,
          title: 'Employee ID',
          dataClass: 'text-muted',
          width: '10%',
          filterType: 'number'
        },
        {
          name: 'displayName',
          sortField: 'displayName',
          title: 'Employee Name',
          titleClass: '',
          dataClass: 'text-muted',
          width: '20%',
          filterType: 'text'
        },
        {
          name: '__slot:roles',
          sortField: 'roles',
          title: 'Roles',
          dataClass: 'text-muted',
          width: '20%',
          filterType: 'text'
        },
        {
          name: '__slot:permissions',
          sortField: 'permissions',
          title: 'Permissions',
          dataClass: 'text-muted',
          width: '20%',
          filterType: 'text'
        },
        {
          name: 'azureGuid',
          sortField: 'azureGuid',
          title: 'Azure GUID',
          dataClass: 'text-muted',
          width: '20%',
          filterType: 'text'
        },
        {
          name: '__slot:activeEmployee',
          sortField: 'active',
          title: 'Active Employee',
          titleClass: 'center aligned text-center',
          width: '10%',
          filterType: 'active'
        }
      ]
    }
  },
  methods: {
    ...mapMutations(['setDangerMessage', 'setAlertMessage', 'setInfoMessage', 'appendInfoMessage']),

    // Vue Table plumbing methods (standardized)

    makeQueryParams (sortOrder, currentPage, perPage) {
      this.selectedItems = []
      return sortOrder[0]
        ? {
          sort: sortOrder[0]
            ? sortOrder[0].field + '|' + sortOrder[0].direction
            : '',
          page: currentPage,
          per_page: this.perPage,
          search: this.search,
          filters: JSON.stringify(this.filters)
        }
        : {
          page: currentPage,
          per_page: this.perPage,
          search: this.search,
          filters: JSON.stringify(this.filters)
        }
    },
    onVuetableLoading () {
      this.gridLoading = true
    },
    onVuetableLoaded () {
      this.gridLoading = false
    },
    onPaginationData (paginationData) {
      this.from = paginationData.from
      this.to = paginationData.to
      this.total = paginationData.total
      this.lastPage = paginationData.last_page
      this.items = paginationData.data
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    changePageSize (perPage) {
      this.perPage = perPage
      this.refreshVueTable()
    },
    searchChange (val) {
      this.search = val
      this.refreshVueTable()
    },
    filterChange (filters) {
      this.filters = filters
      this.refreshVueTable()
    },
    handleLoadError (response) {
      this.setDangerMessage(response)
    },
    onRowClass (dataItem, index) {
      var outputclass = ''
      if (this.selectedItems.includes(dataItem[this.$data.idfield])) {
        outputclass = 'selected'
      }
      return outputclass
    },
    cellclicked (dataitem, field, event) {
    },
    rightClicked (dataItem, field, event) {
      event.preventDefault()
      this.rightClickedItem = dataItem
      const itemId = dataItem[this.$data.idfield]

      if (!this.$refs.vuetable.selectedTo.includes(itemId)) {
        this.$refs.vuetable.selectedTo = [itemId]
      }
      this.$refs.contextmenu.show({ top: event.pageY, left: event.pageX })
      this.selectedItems = this.$refs.vuetable.selectedTo
    },
    getIndex (value, arr, prop) {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i][prop] === value) {
          return i
        }
      }
      return -1
    },
    refreshVueTable () {
      this.$refs.vuetable.refresh()
    },
    rowClicked (dataItem, event) {
      const itemId = dataItem[this.$data.idfield]

      if (this.$refs.vuetable.selectedTo.includes(itemId)) {
        this.$refs.vuetable.selectedTo = this.$refs.vuetable.selectedTo.filter(x => x !== itemId)
      } else {
        this.$refs.vuetable.selectedTo.push(itemId)
      }
      this.selectedItems = this.$refs.vuetable.selectedTo
    },

    // End of Standard Vue Table methods

    // Component-specific Vue Table methods

    onContextMenuAction (action) {
      switch (action) {
        case 'roles_or_permissions':
        {
          this.$router.push({ name: 'employee-roles-and-permissions', params: { employeeID: this.selectedItems[0] } })
          break
        }
        default:
          break
      }
    },

    // End of Component-specific Vue Table methods

    // Component-specific Methods

    formatDate (value) {
      if (value === null) {
        return ''
      } else {
        return moment(value, 'YYYY-MM-DD').format('MMM Do, YYYY')
      }
    },
    async addUserToHorizon () {
      await this.$refs.AddHorizonUserModal.show({
        exitFunction: () => {
          this.$refs.vuetable.refresh()
        }
      })
    },
    flipActive (employee) {
      this.$refs.Confirmation.show({
        title: employee.active ? 'Activate Employee' : 'Deactivate Employee',
        message:
          'Are you sure you want to ' +
          (employee.active ? 'activate' : 'deactivate') +
          ` ${employee.displayName}?`,
        okButton: 'Yes',
        exitFunction: e => {
          if (['headerclose', 'esc', 'backdrop'].includes(e.trigger)) {
            this.refreshVueTable()
          }
        }
      }).then(async result => {
        if (result) {
          var employeeToDeactivate = {
            EmployeeID: employee.employeeID,
            displayName: employee.displayName,
            active: employee.active
          }
          this.setInfoMessage('')
          await this.deactivateEmployee(employeeToDeactivate)
            .then(response => {
              this.refreshVueTable()
            })
            .catch(err => {
              if (err.response) {
                // client received an error response (5xx, 4xx)
                this.setDangerMessage(err.message)
              } else if (err.request) {
                // client never received a response, or request never left
                this.setDangerMessage(err.message)
              } else {
                // anything else
                this.setDangerMessage(err.message)
              }
            })
        } else {
          this.refreshVueTable()
        }
      })
    }
    // End of Component-specific methods
  }
}
</script>
